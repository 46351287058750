import React from "react"
import styled, {keyframes} from "styled-components"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTooth } from "@fortawesome/free-solid-svg-icons"

const pulse = keyframes`
  0% {
		transform: scale(0.85);
		color: rgba(112, 223, 223, .35);
	}

	70% {
		transform: scale(1);
		color: rgba(112, 223, 223, .75);
	}

	100% {
		transform: scale(0.95);
		color: rgba(112, 223, 223, 1);
	}
`;

export const LoadingToothContainer = styled.div`
  min-height: 100vh;
  text-align: center;
  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .toothIcon {
      width: 75px;
      height: 75px;
      animation: ${pulse} 1.5s ease-in-out infinite alternate;
    }
  }
`;



const loadingTooth = () => {
    return (
      <LoadingToothContainer>
        <div>
          <FontAwesomeIcon icon={faTooth} className="toothIcon" />
        </div>
      </LoadingToothContainer>
    )
}

export default loadingTooth;