import React from "react"
import styled from "styled-components"

const  BtnOutlined = styled.a`
    display: block;
    background: ${props => props.bg || "transparent"};
    text-decoration: none;
    color: ${props => props.color || "#084A81"};
    border: 1px solid ${props => props.color || "#084A81"};
    border-radius: 10px;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    width: 200px;
    margin: auto;
    text-align: center;
    outline: none;
    position: relative;
    :hover {
        :before {
            background-position: left bottom;
        }
    }
    :before{
        content: '';
        display: block;
        background: linear-gradient(to left, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.4) 50%);
        background-size: 210% 100%;
        background-position: right bottom;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        bottom:0;
        right:0;
        left: 0;
        border-radius: 10px;
        transition: all 1s;
        -webkit-transition: all 1s;
      }
`;

const ButtonOutlined = ({children, color, bg, link, target, noFollow}) => {
    return (
        <BtnOutlined href={link} color={color} bg={bg} target={target} rel={noFollow && "nofollow"}>
            {children}
        </BtnOutlined>
    )
}

export default ButtonOutlined;