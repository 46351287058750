import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Layout from "../layout/layout"
import Fade from "react-reveal/Fade"
import LinkOutlined from "../components/link/linkOutlined"
import LoadingTooth from "../components/loadingTooth/loadingTooth"

const SubTitleOutlined = styled.h2`
  color: ${({ theme }) => theme.white};
  text-shadow: -1px -1px 0 ${({ theme }) => theme.primaryColor}, 1px -1px 0 ${({ theme }) => theme.primaryColor}, -1px 1px 0 ${({ theme }) => theme.primaryColor}, 1px 1px 0 ${({ theme }) => theme.primaryColor};
  font-weight: 600;
  text-align: left;
  font-size: 40px;
  z-index: 4;
  margin-bottom: 0;
  margin-left: 5vw;
  @media (max-width: 1080px) {
    font-size: 30px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 31.33% 31.33% 31.33%;
  width: 90%;
  margin: 20px auto;
  grid-column-gap: 3%;
  column-gap: 3%;
  @media (max-width: 1420px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 1080px) {
    display: block;
    width: 70%;
  }
`;

const WrapperPost = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 10vh;
  position: relative;
  @media (max-width: 1080px) {
    margin-bottom: 20vw;
  }
  img {
    width: 100%;
    height: auto;
  }
  p{
    color: ${({ theme }) => theme.primaryColor};
    font-size: 18px;
    padding: 0 30px;
    line-height: 25px;
    text-align: center;
    font-weight: 500;
    position: relative;
    top: 0;
    @media (max-width: 1080px) {
      padding: 0 0 1rem;
    }
  }
  ButtonOutlined {
    margin: auto;
  }
`;

export default function Eventi() {
  const [instagramPost, setInstagramPost] = useState(0)
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      await fetch(`https://graph.instagram.com/${process.env.GATSBY_IG_USER_ID}/media?fields=permalink,id,children,media_type,caption,media_url,username,timestamp&access_token=${process.env.GATSBY_IG_API_TOKEN}`)
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          setInstagramPost(resultData.data)
        })
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout pageTitle="Eventi - Eugenio Maucieri" pageDescription="Miglior studio dentistico" pageKeywords="Salute, Denti, Chirurgia" pageAuthor="Eugenio Maucieri">
      <Fade left duration={1000}>
        <h1>Eventi e Novità</h1>
      </Fade>
      <SubTitleOutlined>Social @dr.maucieri</SubTitleOutlined>
      <Container>
        {
          loading ? (<LoadingTooth />) : (
            instagramPost && instagramPost.slice(0, 12).map((post, index) => (
              <WrapperPost>
                <img key={`instagram_post_${index}`} src={post.media_url} alt={`Instagram Post ${index} Dr. Maucieri`} />
                <p className="paragraph-social-box-image">
                  {post.caption ? post.caption.split(".")[0].split(":")[0].split("!")[0] + " ..." : ""}
                </p>
                <LinkOutlined
                  color={"#FFFFFF"}
                  bg={"linear-gradient(90deg,rgba(65,236,241,1) 0%,rgba(49,104,155,1) 100%)"}
                  key={`instagram_post_${index}`}
                  children={"Vai al Post"}
                  link={post.permalink}
                  target="_blank"
                  noFollow
                />
              </WrapperPost>
            ))
          )
        }
      </Container>
    </Layout>
  )
}
